<template>
    <div class="p-10 lg:px-20 lg:py-5 mx-auto text-gray-800 relative md:text-left">
        <div class="md:flex items-center -mx-10">
            <div class="mb-5 md:mb-0 w-full md:w-2/6 px-5">
                <div class="mb-10">
                    <h1 class="font-extrabold text-6xl mb-5">{{head.heading}}</h1>
                    <p class="text-xl text-gray-500">{{head.content}}</p>
                    <svg class="relative right-15" v-if="user_loggedin" @click="edit_head(head,head.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
                </div>
                <div id="modal_overlayhead_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
                    <div id="head_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
                        <!-- button close -->
                        <button @click="openModal(false,'head_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
                        <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
                        </button>
                    
                        <!-- header -->
                        <div class="px-4 py-3 border-b border-gray-200">
                        <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
                        </div>
                        <!-- body -->
                        <div class="w-full p-3">
                        <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_head.heading" rows="4"></textarea>
                        </p>
                        <p>Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_head.content" rows="4"></textarea>
                        </p>
                        <div class="modal-footer">
                            <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_head_modal()">Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="inline-block align-bottom">
                        <button @click="start()" class="bg-yellow-500 opacity-75 text-black hover:shadow-lg rounded-full px-10 py-2 font-semibold"> Start Selling</button>
                    </div>
                </div>
            </div>
            <div class="w-full md:w-4/6 px-10">
                <div class="relative">
                     <agile :dots="true" :autoplay="false">
                        <div class="slide ">
                            <!-- <div style="backgroud-color:red;"></div> -->
                            <img src='https://images.unsplash.com/photo-1529815481058-55e5b656f6d6?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ' class="" alt="">
                        </div>
                        <div class="slide h-full w-full m-auto bg-red-500">
                            <h3>slide n</h3>
                        </div>
                    </agile>
                    
                    <!-- <img src="https://pngimg.com/uploads/raincoat/raincoat_PNG53.png" class="w-full relative z-10" alt=""> -->
                    <!-- <div class="border-4 border-yellow-200 absolute top-10 bottom-10 left-10 right-10 z-0"></div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import {db,auth} from '@/firebase/firebaseInit.js';
export default {
    data(){
        return{
            head:[],
            temp_head:[],
            user_loggedin:false
        }
    },
    components:{
        agile: VueAgile 
    },
    methods:{
        openModal (value,id){
            const modal_overlay = document.querySelector('#modal_overlay'+id);
            const modal = document.querySelector('#' + id);
            const modalCl = modal.classList
            const overlayCl = modal_overlay

            if(value){
                modalCl.add('opacity-0')
                modalCl.add('-translate-y-full')
                modalCl.add('scale-150')
                overlayCl.classList.remove('hidden')
                setTimeout(() => {
                modalCl.remove('opacity-0')
                modalCl.remove('-translate-y-full')
                modalCl.remove('scale-150')
                }, 100);
            } 
            else {
                modalCl.add('-translate-y-full')
                setTimeout(() => {
                modalCl.add('opacity-0')
                modalCl.add('scale-150')
                }, 100);
                setTimeout(() => overlayCl.classList.add('hidden'), 300);
            }
        },
        edit_head(data,doc_id){
            console.log(data)
            this.openModal(true,'head_modal')
            this.temp_head = data
            this.temp_head.doc_id = doc_id
        },

        save_head_modal(){
            let data = this.temp_head
            db.collection('body').doc(data.doc_id).set({
                heading : this.temp_head.heading,
                content : this.temp_head.content
            })
            this.openModal(false,'head_modal')
        },
        start(){
            window.location = 'https://www.ombika.com/sell';
        }
    },
    mounted(){
        const user = auth.currentUser;
        if (user) {
        this.user_loggedin = true
        } 
        else {
        this.user_loggedin = false
        } 
        db.collection("body").doc('VCFblaU06zqlRUw7Bp5K').get().then((doc) => {
            this.head = doc.data()
            this.head.doc_id = doc.id
        })             
    }

}
</script>
<style scoped>

</style>