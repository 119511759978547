<template>
  <div v-bind:style = bgc>
    <!-- <button v-if="user_loggedin" @click="logout()">Logout</button> -->
    <div>
      <p v-if="user_loggedin">Edit Background color <input type="color" v-on:input="bgc.backgroundColor = $event.target.value" /> </p>
      <!-- Calculator -->
      <h1 class="text-6xl my-2 font-bold">{{title.heading}}</h1>
      <p class="justify-center mb-5 text-gray-600 flex">{{title.content}}
        <svg class="ml-5" v-if="user_loggedin" @click="edit_title(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
      </p> 
      <div id="modal_overlaytitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
        <div id="title_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
          <!-- button close -->
          <button @click="openModal(false,'title_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
          <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
          </button>
      
          <!-- header -->
          <div class="px-4 py-3 border-b border-gray-200">
          <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
          </div>
          <!-- body -->
          <div class="w-full p-3">
          <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.heading" rows="4"></textarea>
          </p>
          <p v-if="temp_title.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.content" rows="4"></textarea>
          </p>
          <div class="modal-footer">
            <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_title_modal()">Save changes</button>
          </div>
          </div>
        </div>
      </div>
      <div class="bg-blue-300 p-5 shadow-lg rounded-lg w-full md:w-3/4 mx-auto">
        <model-select :options="products" v-model="selected_product" placeholder="Choose your products category"></model-select>
        <p class="mt-5">Percentage: <span class="font-bold text-white">{{selected_product.marketfee_percent}}%</span> </p>
        <h2 class="text-xl mt-5 font-semibold mb-2">Enter the selling price of your product</h2>
        <div style="align-items: center;" class="flex flex-col">
          <input class="text-center w-96 mb-2 p-2 mx-5 font-thin transition duration-200 focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="text" v-model="selling_price" placeholder="Selling price (in Rupees)">
          <button class="border-2 w-96 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="calculate_fee(selling_price)">Calculate Fees</button><br>
        </div>
        <p class="text-red-500 mt-2" v-if="errorText">{{errorText}}</p>

        <div v-if="show_charges && errorText==''">
          <p class="font-semibold">Prices and rates shown below are indicative </p>
          <div class="mt-5 font-semibold flex">
            <p class="ml-20">MarketPlace Fees({{selected_product.marketfee_percent}}%)</p>
            <p class="absolute right-80">{{market_fee}}</p>
          </div>
          <div class="mt-5 font-semibold flex">
            <p class="ml-20">Closing Fees</p>
            <p class="absolute right-80">{{closing_fees.charges}}</p>
          </div>
          
          <div v-if="this.show_shipping">
            <button class="mt-5 border-2 w-96 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="with_shipping()">With Shipping Charges</button>
            <button class="mt-5 border-2 w-96 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="without_shipping()">Without Shipping Charges</button>

            <div class="" v-if="show_with_shipping">
              <p>Shipping fees varies based on distance the product is shipped:</p>
              <button class="mt-5 mb-5 border-2 w-40 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="selected_shipping_category('city')">Within City</button>
              <button class="mt-5 mb-5 border-2 w-40 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="selected_shipping_category('state')">Within State</button>
              <button class="mt-5 mb-5 border-2 w-40 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="selected_shipping_category('metro')">Metro to Metro</button>
              <button class="mt-5 mb-5 border-2 w-40 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="selected_shipping_category('rest')">Rest of India</button>
              <button class="mt-5 mb-5 border-2 w-40 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="selected_shipping_category('special')">Special Regions</button>
              <p class="mb-2">Enter the approximate weight of the product</p>
              <input type="text" v-model="weight" placeholder="Weight(in kgs)">
              <button class="border-2 w-20 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="submit()">Submit</button>
              <p v-if="errorText">{{errorText}}</p>
            </div>

          </div>
          <div v-if="this.show_total">
            <div class="mt-5 mb-5 font-semibold flex">
              <p class="ml-20">Shipping Charges</p>
              <p class="absolute right-80">{{parseFloat(shipping_charges).toFixed(2)}}</p>
            </div>
            <hr>
            <div class="mt-5 font-semibold flex">
              <p class="ml-20">Total Fees (Without GST)</p>
              <p class="absolute right-80">{{without_gst}}</p>
            </div>
            <div class="mt-5 mb-5 font-semibold flex">
              <p class="ml-20">GST on Total Fee(18%)</p>
              <p class="absolute right-80">{{gst}}</p>
            </div>
            <hr>
            <div class="mt-5 font-semibold flex">
              <p class="ml-20">Total Selling Fees (Including GST)</p>
              <p class="absolute right-80">{{grand_total}}</p>
            </div>
            <div class="mt-5 font-semibold flex">
              <p class="ml-20">Seller Profit</p>
              <p class="absolute right-80">{{seller_profit}}</p>
            </div>
            <button class="border-2 w-20 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="back()">Back</button>
          </div>
        </div>
      </div>
      
      <!-- instructions -->
      <div class="mt-6">
        <p class="flex justify-center text-4xl font-bold mt-5 mb-2">{{instructions.heading}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_institle(instructions,instructions.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>
        <div id="modal_overlayinstitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="institle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'institle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.heading" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_institle_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>
        <div v-for="(i,j) in instructions" :key="i.step">
          <div v-if="j!='doc_id' && j!='heading'">
            <div class="flex justify-center mx-auto w-1/2 rounded bg-blue-300 mt-5">
              <div>Step {{j}}: {{i.step}}</div>
              <svg class="relative right-15" v-if="user_loggedin" @click="edit_instructions(i,instructions.doc_id, j)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
            </div>
            <div class="w-1/2 ml-auto mr-auto">{{i.description}}</div>
          </div>
        </div>

        <!-- MODAL -->
        <div id="modal_overlayinstructions_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="instructions_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'instructions_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Step: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ins.step" rows="4"></textarea>
              </p>
              <p v-if="ins.description">Description: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ins.description" rows="4"></textarea>
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_instructions_modal()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- marketfee buttons -->
      <p class="flex justify-center text-4xl font-bold mb-2 mt-5">
        {{title.marheading}}
        <svg class="ml-5" v-if="user_loggedin" @click="edit_martitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
      </p>

      <div id="modal_overlaymartitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
        <div id="martitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
          <!-- button close -->
          <button @click="openModal(false,'martitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
          <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
          </button>
      
          <!-- header -->
          <div class="px-4 py-3 border-b border-gray-200">
          <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
          </div>
          <!-- body -->
          <div class="w-full p-3">
          <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.marheading" rows="4"></textarea>
          </p>
          <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
          <!-- </p> -->
          <div class="modal-footer">
            <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_martitle_modal()">Save changes</button>
          </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(1)">Clothing, Fashion, Fashion Accessories, Jewellery, Luggage, Shoes</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(2)">Electronics and Accessories</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(3)">Grocery, Food and Pet Supplies</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(4)">Health, Beauty, Personal Care and Personal Care Appliances</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(5)">Home, Decor, Home Improvement, Furniture, Outdoor, Lawn and Garden</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(6)">Kitchen, Large and Small Appliances</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(7)">Sports, Gym and Sporting Equipment</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(8)">Business, Industrial, Medical, Scientific Supplies and Office</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(9)">Books, Music, Movies, Video Games, Entertainment</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(10)">Baby Products, Toys and Education</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(11)">Automative, Car and Accessories</button> <br>
        </div>
        <div>
        <button class="bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="expand(12)">Others</button> <br>
        </div>
      </div>
      <!-- marketfee table -->
      <div>
        <div style="display:inline-grid" v-if="show_market_table">
          <div class="bg-blue-300 grid-item font-semibold" style="grid-column: 1 / 2; width:auto;">Product Category</div>
          <div class="bg-blue-300 grid-item font-semibold" style="grid-column: 2 / 3; width:auto;">MarketPlace Fee Percent</div>

          
          <div style="display:contents" v-for="i in sliced_products" :key="i.value">
            <div class="grid-item" style="grid-column: 1 / 2; width:auto;">{{i.text}}</div>
            <div class="grid-item" style="grid-column:; width:auto;">{{i.marketfee_percent}}%</div>
            <svg v-if="user_loggedin" @click="edit_marketfeetable(i)" style="margin-left:20px; grid-column: 3 / 4" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>  
          </div>
        </div>

        <div id="modal_overlaymarketfee_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="marketfee_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'marketfee_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Text: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="market.text" rows="4"></textarea>
              </p>
              <p>MarketPlace Fee Percent: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="market.marketfee_percent" rows="4"></textarea>
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_marketfee_modal()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- closing fee table -->
      <div>
        <p class="text-4xl font-bold mb-2 mt-5"> {{title.closingheading}} </p>
        <p class="flex justify-center">  
          {{title.closingcontent}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_closingtitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlayclosingtitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="closingtitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'closingtitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.closingheading" rows="4"></textarea>
            </p>
            <p v-if="temp_title.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.closingcontent" rows="4"></textarea>
            </p>
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_closingtitle_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>

        <div class="inline-grid">
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 1 / 2;">Product Selling Price Category(INR)</div>
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 2 / 3;">Closing Fee (INR)</div>

          <div style="display:contents" v-for="i in closing_fees" :key="i.category">
            <div class="grid-item" style="grid-column: 1 / 2">{{i.category}}</div>
            <div class="grid-item" style="grid-column: 2 / 3">{{i.charges}}</div>
            <svg v-if="user_loggedin" @click="edit_closingfeetable(i)" style="margin-left:20px; grid-column: 3 / 4" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>  
          </div>
        </div>
        
        <div id="modal_overlayclosingfee_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="closingfee_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'closingfee_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Category: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="closing.category" rows="4" disabled>
              </p>
              <p>Charges: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="closing.charges" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_closingfee_modal()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- admin shipping fees table -->
      <div v-if="user_loggedin">
        <p class="flex justify-center text-4xl font-bold mb-2 mt-5"> 
          {{title.adshipping}} 
          <svg class="ml-5" v-if="user_loggedin" @click="edit_adshippingtitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlayadshippingtitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="adshippingtitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'adshippingtitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.adshipping" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_adshippingtitle_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>
        <div class="inline-grid">
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 1 / 2">Product Weight(kg)</div>                 
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 2 / 3">Within City (INR)</div>                 
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 3 / 4">Within State (INR)</div>                 
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 4 / 5">Rest of India (INR)</div>                 
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 5 / 6">Metro to Metro (INR)</div>                 
          <div class="bg-blue-300 grid-item font-semibold" style="width:auto; grid-column: 6 / 7">Special Regions (INR)</div>                 

          <div v-for="i in shipping_fees" :key="i.weight" style="display:contents">
            <div class="grid-item" style="width:auto; grid-column: 1 / 2">{{i.weight}}</div>                 
            <div class="grid-item" style="width:auto; grid-column: 2 / 3">{{i.city_price}}</div>                 
            <div class="grid-item" style="width:auto; grid-column: 3 / 4">{{i.state_price}}</div>                 
            <div class="grid-item" style="width:auto; grid-column: 4 / 5">{{i.rest_price}}</div>                 
            <div class="grid-item" style="width:auto; grid-column: 5 / 6">{{i.metro_price}}</div>                 
            <div class="grid-item" style="width:auto; grid-column: 6 / 7">{{i.special_price}}</div>                 
            <svg v-if="user_loggedin" @click="edit_shippingfeetable(i)" style="margin-left:20px; grid-column: 7 / 8" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>  
          </div>
        </div>

        <div id="modal_overlayshippingfee_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0 mt-12">
          <div id="shippingfee_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingfee_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Weight: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship_admin.weight" rows="4">
              </p>
              <p>Within City: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship_admin.city_price" rows="4">
              </p>
              <p>Within State: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship_admin.state_price" rows="4">
              </p>
              <p>Rest of India: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship_admin.rest_price" rows="4">
              </p>
              <p>Metro to Metro: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship_admin.metro_price" rows="4">
              </p>
              <p>Special Regions: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship_admin.special_price" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingfee_modal()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Shipping Fees shippingtable1-->
      <div>
        <p class="flex justify-center text-4xl font-bold mb-2 mt-5"> 
          {{title.shipping}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_shippingtitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlayshippingtitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.shipping" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtitle_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>

        <div style="display: inline-grid;">
          <div class="bg-blue-300 grid-item" style="width:auto; grid-row: 1 / span 2;"><strong>Zone</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-row: 1 / span 2;"><strong>Region</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 3 / 6;"><strong>Air</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 6 / 9;"><strong>Surface</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 3 / 4;"><strong>First 500gms</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess 500gms</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Express TAT</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>First 500gms</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess 500gms</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Economy TAT</strong></div>

          <div style="display:contents" v-for="(i,index) in shipping_table1.PartOne" :key="i.Zone">
            <div class="grid-item font-semibold" style="width:auto; grid-column: 1 / 2;">{{i.Zone}}</div>
            <div class="grid-item font-semibold" style="width:auto;">{{i.Region}}</div>
            <div class="grid-item" style="width:auto;">{{i.air_first500}}</div>
            <div class="grid-item" style="width:auto;">{{i.air_excess500}}</div>
            <div class="grid-item" style="width:auto;">{{i.ExpressTAT}}</div>
            <div class="grid-item" style="width:auto;">{{i.surface_first500}}</div>
            <div class="grid-item" style="width:auto;">{{i.surface_excess500}}</div>
            <div class="grid-item" style="width:auto;">{{i.EconomyTAT}}</div> 
            <svg v-if="user_loggedin" @click="edit_shippingtable1_PartOne(i,shipping_table1.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>

          <div style="display: contents;" v-for="(i,index) in shipping_table1.PartTwo" :key="i.head">
            <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 3;"><strong>{{i.head}}</strong></div>
            <div class="grid-item" style="width:auto; grid-column: 3 / 9;">{{i.value}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable1_PartTwo(i,shipping_table1.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>

          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 9;"><strong>Pricing is exclusive of applicable taxes</strong></div>

        </div>

        <div id="modal_overlayshippingtable1_modal1" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable1_modal1" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable1_modal1')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p class="align-text-bottom ">Zone: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.Zone" rows="4">
              </p>
              <p class="align-text-bottom ">Region: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.Region" rows="4">
              </p>
              <p class="align-text-bottom ">(Air) First 500 gms: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.air_first500" rows="4">
              </p>
              <p class="align-text-bottom ">(Air) Excess 500 gms: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.air_excess500" rows="4">
              </p>
              <p class="align-text-bottom ">(Air) Express TAT: <input class="inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.ExpressTAT" rows="4">
              </p>
              <p class="align-text-bottom ">(Surface) First 500 gms: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.surface_first500" rows="4">
              </p>
              <p class="align-text-bottom ">(Surface) Excess 500 gms: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.surface_excess500" rows="4">
              </p>
              <p class="align-text-bottom ">(Surface) Economy TAT: <input class="relative left-2 inline-block w-7/8 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_1.EconomyTAT" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable1_PartOne()">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <div id="modal_overlayshippingtable1_modal2" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable1_modal2" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable1_modal2')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Head: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.head" rows="4">
              </p>
              <p>Value: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.value" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable1_PartTwo()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Reverse Close Pick-up shippingtable2 -->
      <div>
        <p class="flex justify-center text-4xl font-bold mb-2 mt-5"> 
          {{title.reverse}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_reversetitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlayreversetitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="reversetitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'reversetitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.reverse" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_reversetitle_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>

        <div style="display: inline-grid;">
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 5;">RVP</div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-row: 2 / 4;"><strong>Zone</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-row: 2 / 4;"><strong>Region</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 3 / 5;"><strong>Rates</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 3 / 4;"><strong>First 500gms</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess 500gms</strong></div>

          <div style="display: contents;" v-for="(i,index) in shipping_table2.PartOne" :key="i.Zone">
            <div class="grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{i.Zone}}</strong></div>
            <div class="grid-item" style="width:auto;"><strong>{{i.Region}}</strong></div>
            <div class="grid-item" style="width:auto;"><strong>{{i.first500}}</strong></div>
            <div class="grid-item" style="width:auto;"><strong>{{i.excess500}}</strong></div>
            <svg v-if="user_loggedin" @click="edit_shippingtable2_PartOne(i,shipping_table2.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>

          <div style="display: contents;" v-for="(i,index) in shipping_table2.PartTwo" :key="i.head">
            <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 3"><strong>{{i.head}}</strong></div>
            <div class="grid-item" style="width:auto; grid-column: 3 / 5;">{{i.value}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable2_PartTwo(i,shipping_table2.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 5;"><strong>Pricing is exclusive of applicable taxes</strong></div>
        </div>

        <div id="modal_overlayshippingtable2_modal1" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable2_modal1" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable2_modal1')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Zone: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship2_1.Zone" rows="4">
              </p>
              <p>Region: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship2_1.Region" rows="4">
              </p>
              <p>(Air) First 500 gms: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship2_1.first500" rows="4">
              </p>
              <p>(Air) Excess 500 gms: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship2_1.excess500" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable2_PartOne()">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <div id="modal_overlayshippingtable2_modal2" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable2_modal2" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable2_modal2')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Head: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.head" rows="4">
              </p>
              <p>Value: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.value" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable2_PartTwo()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 5 KG Slab shippingtable3-->
      <div>
        <p class="flex justify-center text-4xl font-bold mb-2 mt-5"> 
          {{title.kg5}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_kg5title(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlaykg5title_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="kg5title_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'kg5title_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.kg5" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_kg5title_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>

        <div style="display: inline-grid; grid-template-columns:repeat(5,1fr)">
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Region</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Base Cost</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess Cost</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Base Weigh(kgs)</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess Weight (kg)</strong></div>

          <div style="display: contents;" v-for="(i,index) in shipping_table3.PartOne" :key="i.Zone">
            <div class="grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{i.Region}}</strong></div>
            <div class="grid-item" style="width:auto;">{{i.base_cost}}</div>
            <div class="grid-item" style="width:auto;">{{i.excess_cost}}</div>
            <div class="grid-item" style="width:auto;">{{i.base_weight}}</div>
            <div class="grid-item" style="width:auto;">{{i.excess_weight}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable3_PartOne(i,shipping_table3.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>

          <div style="display: contents;" v-for="(i,index) in shipping_table3.PartTwo" :key="i.head">
            <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{i.head}}</strong></div>
            <div class="grid-item" style="width:auto; grid-column: 2 / 6;">{{i.value}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable3_PartTwo(i,shipping_table3.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 6;"><strong>Above prices are exclusive of applicable taxes</strong></div>
        </div>

        <div id="modal_overlayshippingtable3_modal1" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable3_modal1" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable3_modal1')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Region: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship3_1.Region" rows="4">
              </p>
              <p>Base Cost: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship3_1.base_cost" rows="4">
              </p>
              <p>Excess Cost: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship3_1.excess_cost" rows="4">
              </p>
              <p>Base Weight: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship3_1.base_weight" rows="4">
              </p>
              <p>Excess Weight: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship3_1.excess_weight" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable3_PartOne()">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <div id="modal_overlayshippingtable3_modal2" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable3_modal2" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable3_modal2')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Head: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.head" rows="4">
              </p>
              <p>Value: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.value" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable3_PartTwo()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 10 KG Slab shippingtable4-->
      <div>
        <p class="flex justify-center text-4xl font-bold mb-2 mt-5"> 
          {{title.kg10}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_kg10title(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlaykg10title_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="kg10title_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'kg10title_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.kg10" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_kg10title_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>

        <div style="display: inline-grid; grid-gap:0px; grid-template-columns:repeat(5,1fr)">
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Region</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Base Cost</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess Cost</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Base Weigh(kgs)</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Excess Weight (kg)</strong></div>
          
          <div style="display: contents;" v-for="(i,index) in shipping_table4.PartOne" :key="i.Zone">
            <div class="grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{i.Region}}</strong></div>
            <div class="grid-item" style="width:auto;">{{i.base_cost}}</div>
            <div class="grid-item" style="width:auto;">{{i.excess_cost}}</div>
            <div class="grid-item" style="width:auto;">{{i.base_weight}}</div>
            <div class="grid-item" style="width:auto;">{{i.excess_weight}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable3_PartOne(i,shipping_table4.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>

          <div style="display: contents;" v-for="(i,index) in shipping_table4.PartTwo" :key="i.head">
            <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{i.head}}</strong></div>
            <div class="grid-item" style="width:auto; grid-column: 2 / 6;">{{i.value}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable3_PartTwo(i,shipping_table4.doc_id,index)" style="grid-column: 9 / 10; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>
          <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 6;"><strong>Above prices are exclusive of applicable taxes</strong></div>

        </div>
      </div>

      <!-- Distribution proposal for B2B Pre Kg basis services for Prepaid orders shippingtable5-->
      <div>
        <p class="flex justify-center text-4xl font-bold mb-2 mt-5"> 
          {{title.distribution}}
          <svg class="ml-5" v-if="user_loggedin" @click="edit_distributiontitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </p>

        <div id="modal_overlaydistributiontitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="distributiontitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'distributiontitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
            <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
            <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.distribution" rows="4"></textarea>
            </p>
            <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
            <!-- </p> -->
            <div class="modal-footer">
              <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_distributiontitle_modal()">Save changes</button>
            </div>
            </div>
          </div>
        </div>
        <div style="display: inline-grid; grid-gap:0px; grid-template-columns:repeat(10,1fr)">
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Price</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>North</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>West</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>South</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Central</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>East</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>J and K</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Kerala</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Guwahati</strong></div>
          <div class="bg-blue-300 grid-item" style="width:auto;"><strong>Rest of NE</strong></div>

          <div style="display: contents;" v-for="(i,index) in shipping_table5.PartOne" :key="i.Zone">
            <div class="grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{i.Region}}</strong></div>
            <div class="grid-item" style="width:auto;">{{i.north}}</div>
            <div class="grid-item" style="width:auto;">{{i.west}}</div>
            <div class="grid-item" style="width:auto;">{{i.south}}</div>
            <div class="grid-item" style="width:auto;">{{i.central}}</div>
            <div class="grid-item" style="width:auto;">{{i.east}}</div>
            <div class="grid-item" style="width:auto;">{{i.jandk}}</div>
            <div class="grid-item" style="width:auto;">{{i.kerala}}</div>
            <div class="grid-item" style="width:auto;">{{i.guwahati}}</div>
            <div class="grid-item" style="width:auto;">{{i.rest}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable5_PartOne(i,shipping_table5.doc_id,index)" style="grid-column: 12 / 13; margin-left:20px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>

          <div style="display: contents;" v-for="(j,index) in shipping_table5.PartTwo" :key="j.head">
            <div class="bg-blue-300 grid-item" style="width:auto; grid-column: 1 / 2"><strong>{{j.head}}</strong></div>
            <div class="grid-item" style="width:auto; grid-column: 2 / 3;">{{j.value}}</div>
            <svg v-if="user_loggedin" @click="edit_shippingtable5_PartTwo(j,shipping_table5.doc_id,index)" style="margin:10px 0px 0px 10px" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
          </div>
        </div>

        <div id="modal_overlayshippingtable5_modal1" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable5_modal1" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable5_modal1')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Region: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.Region" rows="4">
              </p>
              <p>North: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.north" rows="4">
              </p>
              <p>West: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.west" rows="4">
              </p>
              <p>South: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.south" rows="4">
              </p>
              <p>Central: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.central" rows="4">
              </p>
              <p>East: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.east" rows="4">
              </p>
              <p>J and K: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.jandk" rows="4">
              </p>
              <p>Kerala: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.kerala" rows="4">
              </p>
              <p>Guwahati: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.guwahati" rows="4">
              </p>
              <p>Rest of NE: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship5_1.rest" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable5_PartOne()">Save changes</button>
              </div>
            </div>
          </div>
        </div>

        <div id="modal_overlayshippingtable5_modal2" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
          <div id="shippingtable5_modal2" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
            <!-- button close -->
            <button @click="openModal(false,'shippingtable5_modal2')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
            </button>
        
            <!-- header -->
            <div class="px-4 py-3 border-b border-gray-200">
              <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
            </div>
            <!-- body -->
            <div class="w-full p-3">
              <p>Head: <input class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.head" rows="4">
              </p>
              <p>Value: <input class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="ship1_2.value" rows="4">
              </p>
              <div class="modal-footer">
                <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_shippingtable5_PartTwo()">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="footer bg-white relative pt-1 border-b-2 border-blue-700">
        <div class="container mx-auto px-6 bg-blue-300">
          <div class="mt-16 border-gray-300 flex flex-col items-center">
            <div class="sm:w-2/3 text-center py-6">
              <p class="flex justify-center text-sm text-black font-bold mb-2">
                {{title.footer}}
                <svg class="ml-5" v-if="user_loggedin" @click="edit_footertitle(title,title.doc_id)" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="slategrey" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
              </p>

              <div id="modal_overlayfootertitle_modal" style="position:relative" class="hidden absolute inset-0 bg-black bg-opacity-30 h-screen w-full flex justify-center items-start md:items-center pt-10 md:pt-0">
                <div id="footertitle_modal" class="opacity-0 transform -translate-y-full scale-150  relative w-10/12 md:w-6/12 pb-5 bg-white rounded shadow-lg transition-opacity transition-transform duration-300">
                  <!-- button close -->
                  <button @click="openModal(false,'footertitle_modal')" class="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
                  <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
                  </button>
              
                  <!-- header -->
                  <div class="px-4 py-3 border-b border-gray-200">
                  <h2 class="text-xl text-center font-semibold text-gray-600">Edit details</h2>
                  </div>
                  <!-- body -->
                  <div class="w-full p-3">
                  <p>Heading: <textarea class="relative left-6 inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_title.footer" rows="4"></textarea>
                  </p>
                  <!-- <p v-if="temp_institle.content">Content: <textarea class="inline-block align-middle w-3/4 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" v-model="temp_institle.content" rows="4"></textarea> -->
                  <!-- </p> -->
                  <div class="modal-footer">
                    <button class="mt-5 bg-transparent hover:bg-blue-300 text-black-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" @click="save_footertitle_modal()">Save changes</button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </div>
  </div>
</template>

<script>

import {db,auth} from '@/firebase/firebaseInit.js';
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'
export default {
  name: 'HelloWorld',
  data(){
    return{
      user_loggedin:false,
      bgc:{
        backgroundColor:'', 
      },
      selling_price:'',
      products:[],
      selected_product:{
        text:'',
        marketfee_percent:'',
        min_val:'',
        max_val:'',
      },
      instructions:[],
      closing_fees:[],
      show_charges : false,
      shipping_fees:[],
      show_shipping:false,
      show_with_shipping : false,
      weight:'',
      errorText:'',
      shipping_category:'',
      shipping_charges:'',
      show_total:false,
      ins:{
        step:'',
        description:''
      },
      sliced_products:'',
      show_market_table : false,
      market:{
        text:'',
        marketfee_percent:''
      },
      closing:{
        category:'',
        charges:''
      },
      ship_admin:{
        weight:'',
        city_price:'',
        state_price:'',
        rest_price:'',
        metro_price:'',
        special_price:''
      },
      shipping_table1:[],
      ship1_1:{
        Zone:'',
        Region:'',
        air_first500:'',
        air_excess500:'',
        ExpressTAT:'',
        surface_first500:'',
        surface_excess500:'',
        EconomyTAT:''
      },
      ship1_2:{
        head:'',
        value:''
      },
      shipping_table2:[],
      ship2_1:{
        Zone:'',
        Region:'',
        excess500:'',
        first500:''
      },
      shipping_table3:[],
      ship3_1:{
        Region:'',
        base_cost:'',
        excess_cost:'',
        base_weight:'',
        excess_weight:''
      },
      ship5_1:{
        Region:'',
        north:'',
        west:'',
        south:'',
        central:'',
        east : '',
        jandk : '',
        kerala : '',
        guwahati : '',
        rest : ''
      },
      shipping_table4:[],
      shipping_table5:[],
      title:[],
      temp_title:[],
      temp_institle:[],
    }
  },

  components:{
    ModelSelect
  },

  computed:{
    market_fee(){
      let m = (this.selected_product.marketfee_percent / 100) * this.selling_price
      return (parseFloat(m).toFixed(2))
    },
    without_gst(){
      let w = parseFloat(this.market_fee) + parseFloat(this.closing_fees.charges) + parseFloat(this.shipping_charges)
      return (parseFloat(w).toFixed(2))
    },
    gst(){
      let g = (this.without_gst*18)/100
      return (parseFloat(g).toFixed(2))
    },
    grand_total(){
      let t = parseFloat(this.without_gst) + parseFloat(this.gst)
      return (parseFloat(t).toFixed(2))
    },
    seller_profit(){
      let s = parseFloat(this.selling_price) - parseFloat(this.grand_total)
      return (parseFloat(s).toFixed(2))
    }
  },

  mounted(){

    const user = auth.currentUser;
    if (user) {
      this.user_loggedin = true
    } 
    else {
      this.user_loggedin = false
    } 

    db.collection("body").doc('62riuEcXWn3FAEEB4UQw').get().then((doc) => {
      this.title = doc.data()
      this.title.doc_id = doc.id
    })  

    db.collection("options").get().then((p) => {
      p.forEach((doc) => {
        let temp_options = {}
        temp_options.value = doc.data().value
        temp_options.text = doc.data().text
        temp_options.marketfee_percent = doc.data().marketfee_percent
        temp_options.min_val = doc.data().min_val
        temp_options.max_val = doc.data().max_val
        temp_options.doc_id = doc.id
        this.products.push(temp_options)
        this.products = this.products.sort((a,b) => {
          return a.value - b.value
        })
      });
    })

    db.collection("instructions").doc("4qpvdbHm4pNgem8UOyT8").get().then((doc) => {
      this.instructions = doc.data()
      this.instructions.doc_id = doc.id
    })

    db.collection("closing_fee").get().then((c) => {
      c.forEach((doc) => {
        let temp_closingfee = {}
        temp_closingfee = doc.data()
        temp_closingfee.doc_id = doc.id
        this.closing_fees.push(temp_closingfee)
        this.closing_fees = this.closing_fees.sort((a,b) => {
          return a.charges - b.charges
        })
      })
    })

    db.collection('shipping_fee').get().then((s) => {
      s.forEach((doc) => {
        let temp_shippingfee = {}
        temp_shippingfee = doc.data()
        temp_shippingfee.doc_id = doc.id
        this.shipping_fees.push(temp_shippingfee)
        this.shipping_fees = this.shipping_fees.sort((a,b) => {
          return a.weight - b.weight
        })
      })
    })

    db.collection('public_shippingfee').doc('JKFWUeAhMaMe7Sz29Jrq').get().then((doc) => {
      let temp_publicshippingfee = {}
      temp_publicshippingfee = doc.data()
      temp_publicshippingfee.doc_id = doc.id
      this.shipping_table1 = temp_publicshippingfee
    })
    
    db.collection('public_shippingfee').doc('sPZctrs9YL1ayupWJiqM').get().then((doc) => {
      let temp_publicshippingfee = {}
      temp_publicshippingfee = doc.data()
      temp_publicshippingfee.doc_id = doc.id
      this.shipping_table2 = temp_publicshippingfee
    })

    db.collection('public_shippingfee').doc('bAe1mjzLRR6eQMMfOXET').get().then((doc) => {
      let temp_publicshippingfee = {}
      temp_publicshippingfee = doc.data()
      temp_publicshippingfee.doc_id = doc.id
      this.shipping_table3 = temp_publicshippingfee
    })

    db.collection('public_shippingfee').doc('NgzIQoUXhHMwxx5JeG1r').get().then((doc) => {
      let temp_publicshippingfee = {}
      temp_publicshippingfee = doc.data()
      temp_publicshippingfee.doc_id = doc.id
      this.shipping_table4 = temp_publicshippingfee
    })

    db.collection('public_shippingfee').doc('M9R3ljeITyDzwnxGv6qe').get().then((doc) => {
      let temp_publicshippingfee = {}
      temp_publicshippingfee = doc.data()
      temp_publicshippingfee.doc_id = doc.id
      this.shipping_table5 = temp_publicshippingfee
    })
    
  },

  methods:{

    openModal (value,id){
      const modal_overlay = document.querySelector('#modal_overlay'+id);
      const modal = document.querySelector('#' + id);
      const modalCl = modal.classList
      const overlayCl = modal_overlay

      if(value){
        modalCl.add('opacity-0')
        modalCl.add('-translate-y-full')
        modalCl.add('scale-150')
        overlayCl.classList.remove('hidden')
        setTimeout(() => {
          modalCl.remove('opacity-0')
          modalCl.remove('-translate-y-full')
          modalCl.remove('scale-150')
        }, 100);
      } 
      else {
        modalCl.add('-translate-y-full')
        setTimeout(() => {
          modalCl.add('opacity-0')
          modalCl.add('scale-150')
        }, 100);
        setTimeout(() => overlayCl.classList.add('hidden'), 300);
      }
    },

    expand(val){
      if(val == 1){
          this.sliced_products = this.products.slice(0,26) 
      }
      else if(val == 2){
          this.sliced_products = this.products.slice(26,60) 
      }
      else if(val == 3){
          this.sliced_products = this.products.slice(60,63)
      }

      else if(val == 4){
          this.sliced_products = this.products.slice(63,76)
      }
      
      else if(val == 5){
          this.sliced_products = this.products.slice(76,100)
      }

      else if(val == 6){
          this.sliced_products = this.products.slice(100,110)
      }

      else if(val == 7){
          this.sliced_products = this.products.slice(110,118)
      }

      else if(val == 8){
          this.sliced_products = this.products.slice(118,128)
      }

      else if(val == 9){
          this.sliced_products = this.products.slice(128,147)
      }

      else if(val == 10){
          this.x = this.products.slice(147,157)
      }

      else if(val == 11){
          this.x = this.products.slice(157,163)
      }

      else if(val == 12){
          this.x = this.products.slice(163,171)
      }

      this.show_market_table = !this.show_market_table
    },

    edit_footertitle(data,doc_id){
      this.openModal(true,'footertitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_footertitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        footer : this.temp_title.footer,
      })
      this.openModal(false,'footertitle_modal')
    },

    edit_distributiontitle(data,doc_id){
      this.openModal(true,'distributiontitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_distributiontitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        distribution : this.temp_title.distribution,
      })
      this.openModal(false,'distributiontitle_modal')
    },

    edit_kg5title(data,doc_id){
      this.openModal(true,'kg5title_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_kg5title_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        kg5 : this.temp_title.kg5,
      })
      this.openModal(false,'kg5title_modal')
    },

    edit_kg10title(data,doc_id){
      this.openModal(true,'kg10title_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_kg10title_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        kg10 : this.temp_title.kg10,
      })
      this.openModal(false,'kg10title_modal')
    },

    edit_reversetitle(data,doc_id){
      this.openModal(true,'reversetitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_reversetitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        reverse : this.temp_title.reverse,
      })
      this.openModal(false,'reversetitle_modal')
    },

    edit_shippingtitle(data,doc_id){
      this.openModal(true,'shippingtitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_shippingtitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        shipping : this.temp_title.shipping,
      })
      this.openModal(false,'shippingtitle_modal')
    },

    edit_adshippingtitle(data,doc_id){
      this.openModal(true,'adshippingtitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_adshippingtitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        adshipping : this.temp_title.adshipping,
      })
      this.openModal(false,'adshippingtitle_modal')
    },

    edit_closingtitle(data,doc_id){
      this.openModal(true,'closingtitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_closingtitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        closingheading : this.temp_title.closingheading,
        closingcontent : this.temp_title.closingcontent,
      })
      this.openModal(false,'closingtitle_modal')
    },

    edit_martitle(data,doc_id){
      this.openModal(true,'martitle_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_martitle_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
        marheading : this.temp_title.marheading,
      })
      this.openModal(false,'martitle_modal')
    },

    edit_institle(data,doc_id){
      this.openModal(true,'institle_modal')
      this.temp_institle = data
      this.temp_institle.doc_id = doc_id
    },

    save_institle_modal(){
      let data = this.temp_institle
      db.collection('instructions').doc(data.doc_id).update({
          heading : this.temp_institle.heading,
      })
      this.openModal(false,'institle_modal')
    },

    edit_title(data,doc_id){
      this.openModal(true,'title_modal')
      this.temp_title = data
      this.temp_title.doc_id = doc_id
    },

    save_title_modal(){
      let data = this.temp_title
      db.collection('body').doc(data.doc_id).update({
          heading : this.temp_title.heading,
          content : this.temp_title.content
      })
      this.openModal(false,'title_modal')
    },

    edit_instructions(data,doc_id,index){
      this.openModal(true,'instructions_modal')
      this.ins = data
      this.ins.index = index
      this.ins.doc_id = doc_id
    },

    save_instructions_modal(){
      let data = this.ins
      let temp_id = data.index
      db.collection('instructions').doc(data.doc_id).update({
        [`${temp_id}`] : {
          step : this.ins.step,
          description : this.ins.description
        }
      })
      this.openModal(false,'instructions_modal')
    },

    edit_marketfeetable(data){
      this.openModal(true,'marketfee_modal')
      this.market = data
    },

    save_marketfee_modal(){
      let data = this.market
      db.collection('options').doc(data.doc_id).update({
        value : data.value,
        text : this.market.text,
        marketfee_percent : this.market.marketfee_percent,
        min_val : data.min_val,
        max_val : data.max_val
      })
      let temp = this.products.find( ({ value }) => value ===  data.value);
      temp.text = this.market.text
      temp.marketfee_percent = this.market.marketfee_percent
      this.openModal(false,'marketfee_modal')
    },

    edit_closingfeetable(data){
      this.openModal(true,'closingfee_modal')
      this.closing = data
    },

    save_closingfee_modal(){
      let data = this.closing
      db.collection('closing_fee').doc(data.doc_id).update({
        category : data.category,
        charges : data.charges
      })
      this.openModal(false,'closingfee_modal')
    },

    edit_shippingfeetable(data){
      this.openModal(true, 'shippingfee_modal')
      this.ship_admin = data
    },

    save_shippingfee_modal(){
      let data = this.ship_admin
      db.collection('shipping_fee').doc(data.doc_id).update({
        weight : data.weight,
        city_price : data.city_price,
        state_price : data.state_price,
        metro_price : data.metro_price,
        rest_price : data.rest_price,
        special_price : data.special_price
      })
      this.openModal(false,'shippingfee_modal')
    },

    edit_shippingtable1_PartOne(data, doc_id, index){
      this.openModal(true,'shippingtable1_modal1')
      this.ship1_1 = data
      this.ship1_1.index = index
      this.ship1_1.doc_id = doc_id
    },

    save_shippingtable1_PartOne(){
      let data = this.ship1_1
      let temp_id = "PartOne." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          Zone : this.ship1_1.Zone,
          Region : this.ship1_1.Region,
          air_first500 : this.ship1_1.air_first500,
          air_excess500 : this.ship1_1.air_excess500,
          ExpressTAT : this.ship1_1.ExpressTAT,
          surface_first500 : this.ship1_1.surface_first500,
          surface_excess500 : this.ship1_1.surface_excess500,
          EconomyTAT : this.ship1_1.EconomyTAT
        }
      })
      this.openModal(false,'shippingtable1_modal1')
    },

    edit_shippingtable1_PartTwo(data, doc_id, index){
      this.openModal(true,'shippingtable1_modal2')
      this.ship1_2 = data
      this.ship1_2.index = index
      this.ship1_2.doc_id = doc_id
    },

    save_shippingtable1_PartTwo(){
      let data = this.ship1_2
      let temp_id = "PartTwo." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          head : this.ship1_2.head,
          value : this.ship1_2.value
        }
      })
      this.openModal(false,'shippingtable1_modal2')
    },

    edit_shippingtable2_PartOne(data, doc_id, index){
      this.openModal(true,'shippingtable2_modal1')
      this.ship2_1 = data
      this.ship2_1.index = index
      this.ship2_1.doc_id = doc_id
    },

    save_shippingtable2_PartOne(){
      let data = this.ship2_1
      let temp_id = "PartOne." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          Zone : this.ship2_1.Zone,
          Region : this.ship2_1.Region,
          first500 : this.ship2_1.first500,
          excess500 : this.ship2_1.excess500,
        }
      })
      this.openModal(true,'shippingtable2_modal1')
    },
    
    edit_shippingtable2_PartTwo(data, doc_id, index){
      this.openModal(true,'shippingtable2_modal2')
      this.ship1_2 = data
      this.ship1_2.index = index
      this.ship1_2.doc_id = doc_id
    },

    save_shippingtable2_PartTwo(){
      let data = this.ship1_2
      let temp_id = "PartTwo." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          head : this.ship1_2.head,
          value : this.ship1_2.value
        }
      })
      this.openModal(false,'shippingtable2_modal2')
    },

    edit_shippingtable3_PartOne(data, doc_id, index){
      this.openModal(true,'shippingtable3_modal1')
      this.ship3_1 = data
      this.ship3_1.index = index
      this.ship3_1.doc_id = doc_id
    },

    save_shippingtable3_PartOne(){
      let data = this.ship3_1
      let temp_id = "PartOne." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          Region : this.ship3_1.Region,
          base_cost : this.ship3_1.base_cost,
          excess_cost : this.ship3_1.excess_cost,
          base_weight : this.ship3_1.base_weight,
          excess_weight : this.ship3_1.excess_weight,
        }
      })
      this.openModal(false,'shippingtable3_modal1')
    },

    edit_shippingtable3_PartTwo(data, doc_id, index){
      this.openModal(true,'shippingtable3_modal2')
      this.ship1_2 = data
      this.ship1_2.index = index
      this.ship1_2.doc_id = doc_id
    },

    save_shippingtable3_PartTwo(){
      let data = this.ship1_2
      let temp_id = "PartTwo." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          head : this.ship1_2.head,
          value : this.ship1_2.value
        }
      })
      this.openModal(false,'shippingtable3_modal2')
    },

    edit_shippingtable5_PartOne(data, doc_id, index){
      this.openModal(true,'shippingtable5_modal1')
      this.ship5_1 = data
      this.ship5_1.doc_id = doc_id
      this.ship5_1.index = index
    },

    save_shippingtable5_PartOne(){
      let data = this.ship5_1
      let temp_id = "PartOne." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          Region : this.ship5_1.Region,
          north : this.ship5_1.north,
          west : this.ship5_1.west,
          south : this.ship5_1.south,
          central : this.ship5_1.central,
          east : this.ship5_1.east,
          jandk : this.ship5_1.jandk,
          kerala : this.ship5_1.kerala,
          guwahati : this.ship5_1.guwahati,
          rest : this.ship5_1.rest
        }
      })
      this.openModal(false,'shippingtable5_modal1')
    },

    edit_shippingtable5_PartTwo(data, doc_id, index){
      this.openModal(true,'shippingtable5_modal2')
      this.ship1_2 = data
      this.ship1_2.index = index
      this.ship1_2.doc_id = doc_id
    },

    save_shippingtable5_PartTwo(){
      let data = this.ship1_2
      let temp_id = "PartTwo." + data.index 
      db.collection("public_shippingfee").doc(data.doc_id).update({
        [`${temp_id}`] : {
          head : this.ship1_2.head,
          value : this.ship1_2.value
        }
      })
      this.openModal(false,'shippingtable5_modal2')
    },

    calculate_fee(val){
      if(this.selected_product.text!='' && val != ''){
        if((val > this.selected_product.min_val || this.selected_product.min_val =='false') && (val <= this.selected_product.max_val || this.selected_product.max_val =='false')){
          this.errorText = ''
          this.closing_fees.charges = ''
          this.show_charges = true
          this.show_shipping = true
          this.show_total = false
          let temp_sellingprice = this.selling_price
          this.selling_price = ''
          let temp_category 
          if(temp_sellingprice > 0 && temp_sellingprice <= 250){
            temp_category = "0-250"   
          }
          else if(temp_sellingprice > 250 && temp_sellingprice <= 500){
            temp_category = "251-500"
          }
          else if(temp_sellingprice > 500 && temp_sellingprice <= 1000){
            temp_category = "501-1000"
          }
          else if(temp_sellingprice > 1000 && temp_sellingprice <= 5000){
            temp_category = "1001-5000"
          }
          else{
            temp_category = "5001 and Above"
          }
          let temp = (this.closing_fees.find( ({ category }) => category ===  temp_category));      
          this.closing_fees.charges = temp.charges
          this.selling_price = temp_sellingprice
        }
        else{
          this.errorText = 'Price is too high/low for chosen category. Please choose the correct category for this price.'
        }
      }
      else{
        this.errorText = 'Please enter all details first!'
      }
    },

    with_shipping(){
      this.show_with_shipping = !this.show_with_shipping
    },

    selected_shipping_category(val){
      this.shipping_category = val
    },

    submit(){
      if(this.weight!='' && this.shipping_category!=''){
        this.errorText = ''
        let x = this.indexOfClosest(this.shipping_fees, this.weight)
        if(this.shipping_category == 'city'){
          this.shipping_charges = this.shipping_fees[x].city_price
        }
        else if(this.shipping_category == 'state'){
          this.shipping_charges = this.shipping_fees[x].state_price
        }
        else if(this.shipping_category == 'metro'){
          this.shipping_charges = this.shipping_fees[x].metro_price
        }
        else if(this.shipping_category == 'rest'){
          this.shipping_charges = this.shipping_fees[x].rest_price
        }      
        else if(this.shipping_category == 'special'){
          this.shipping_charges = this.shipping_fees[x].special_price
        }
        this.show_shipping = false
        this.show_total = true
      }
      else{
        this.errorText = 'Please enter proper details first'
      }
    },

    without_shipping(){
      this.show_with_shipping = false
      this.shipping_charges = 0
      this.show_shipping = false
      this.show_total = true
    },

    back(){
      this.show_total = false
      this.show_shipping = true
    },

    indexOfClosest(nums, target) {
      nums.sort(function(a, b){
        return a.weight - b.weight
      });
      let closest = Number.MAX_SAFE_INTEGER;
      let index = 0;
      nums.forEach((num, i) => {
        let dist = Math.abs(target - num.weight);
        if (dist < closest) {
          index = i;
          closest = dist;
        }
      });
      
      if(nums[index].weight-target<0){
        this.arr_length = nums.length - 1
        index = index+1
        if(index>this.arr_length){
          index = index -1
        }
      }
      return index;
    }
  }
}
</script>

<style scoped>
.grid-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 05px;
  font-size: 15px;
  text-align: center;
}

/* .modal{
  position: fixed;
  z-index: 1; 
  width: 100%;
  height: 100%; 
  overflow-y:scroll; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-content {
  background-color: #fefefe;
  margin: 10% auto; 
  padding: 20px;
  overflow-y:auto; 
  max-width: 50%; 
} */
</style>
