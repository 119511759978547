<template>
<div class="bg-blue-300 flex items-center justify-center h-screen" >
    <form v-if="showLogin" @submit.prevent class="bg-indigo-800 text-white font-bold rounded-lg border shadow-lg p-10">
        <p class="font-bold text-white text-3xl">Login</p>
        <p class="mt-5 mb-5">Email
            <input class="ml-12 text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="text" placeholder="you@email.com" v-model="email">
        </p>
        <p class="mb-5">Password
            <input class="text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="password" placeholder="********" v-model="password">
        </p>
        <div>
            <p v-if="this.errorText" class="text-red-500 mb-5">{{errorText}}</p>
            <button class="mb-2 border-2 w-80 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="login" type="submit">Login</button>
        </div>
        <div>
            <button class="mb-2 border-2 w-80 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="forgotPassword" type="submit">Forgot Password</button>
        </div>
        <!-- <button class="mb-2 border-2 w-80 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="newAccount">Create New Account</button> -->

    </form>
    <!-- <form v-else @submit.prevent class="bg-indigo-800 text-white font-bold rounded-lg border shadow-lg p-10">
        <p class="font-bold text-white text-3xl">SignUp</p>
        <p class="mt-5 mb-5">Name
            <input class="ml-12 text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="text" placeholder="abc" v-model="name">
        </p>
        <p class="mb-5">Birth Date
            <input class="text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="text" placeholder="dd/mm/yyyy" v-model="dob">
        </p>
        <p class="mb-5">Email
            <input class="ml-12 text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="text" placeholder="you@email.com" v-model="email">
        </p>
        <p class="mb-5">Password
            <input class="text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="password" placeholder="******" v-model="password">
        </p>
        <div>
            <button class="mb-2 border-2 w-80 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="signUp" type="submit">Sign In</button>
            <p v-if="this.errorText"  class="text-danger">{{errorText}}</p>
        </div>
        <p>
            <button class="mb-2 border-2 w-80 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="backToLogin" type="submit">Back to Login</button>
        </p>
    </form> -->
    <passwordReset v-if="showPasswordReset"/>
        

</div>
</template>

<script>

import passwordReset from '../components/PasswordReset.vue'
export default {
    name:'login',
    components: {
        passwordReset
    },
    data() {
        return{
            
            email: '',
            password: '',
            name: '',
            dob : '',

            errorText : null,
            showLogin : true,
            showPasswordReset : false
        }
    },
    
    methods: {
        login(){
            if(this.email)
                this.$store.dispatch('login',{
                    email : this.email,
                    password : this.password,
                })
                // this.$router.push('Home')
            else
                this.errorText = "Please enter email first!!"
        },

        signUp(){
            if(this.email)
                this.$store.dispatch('signUp',{
                    email : this.email,
                    password : this.password,
                    name:this.name,
                    dob:this.dob,
                })
            else
                this.errorText = "Please fill all the details properly!!"
        },

        newAccount(){
            this.errorText = null
            this.showLogin = false
        },

        backToLogin(){
            this.showLogin = true
        },

        forgotPassword(){
            this.showPasswordReset = true
            this.$router.push({name:'passwordReset'})
        }

        // forgotPassword(){
        //     if(this.email)
        //         this.$router.push({name:'dashboard'})
        //     else
        //         this.errorText = "forgot password"
        // },
        // newAccount(){
        //     if(this.email)
        //         this.$router.push({name:'dashboard'})
        //     else
        //         this.errorText = "new account"
        // }
    }
}
</script>

<style >

.login{
    background-color: lavenderblush;
    padding: 25px;
    margin: auto;
    max-width: 500px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}

</style>

