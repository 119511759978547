<template>
    <div class="bg-blue-300 flex items-center justify-center h-screen">
      <form class="bg-indigo-800 text-white font-bold rounded-lg border shadow-lg p-10" @submit.prevent>
        <div class="flex justify-center">
          <p class="font-bold text-white text-3xl">Reset Password</p>
          <button @click="close()" class="relative -right-40 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
            <svg class="m-auto w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" ></path></svg>
          </button>
        </div>
        <div v-if="!success">
          <p class="mt-5 mb-5">Enter your email id to reset your password
            <input class="ml-12 text-black h-8 w-56 mb-2 p-2 mx-5 font-thin focus:shadow-md focus:outline-none ring-offset-2 border border-gray-400 rounded-lg focus:ring-2 focus:ring-blue-600" type="text" v-model="email" placeholder="abc@gmail.com">
          </p>
          <p class="text-red-500 mb-5" v-if="errorMsg!==''">{{errorMsg}}</p>
          <button class="mb-2 border-2 w-80 p-2 text-white rounded-3xl border-white hover:bg-white hover:text-black" @click="resetPassword" type="submit">Reset</button>
        </div>
        <div v-else>
          <p>Success!! Password reset link has been sent to your mail</p>
        </div>
      </form>
    </div>
</template>

<script>
import {auth} from '@/firebase/firebaseInit.js'
export default {
    name: 'passwordReset',
    data() {
      return{
        email:'',
        success:false,
        errorMsg:''
      }
    },
    methods : {
        close(){
          this.$router.push({name:'Login'})
        },
        async resetPassword(){
          console.log(this.email)
          this.errorMsg = ''
          try{
            await auth.sendPasswordResetEmail(this.email)
            this.success = true
          }
          catch(err){
            this.errorMsg = err.message
          }
        }
    }
}
</script>

<style >
.password{
  background-color: lavenderblush;
  border: 2px black solid;
  border-radius: 10px;
  margin: auto;
  max-width: 500px;
  min-height: 300px;
  justify-content:center;
  padding: 60px;
}

.btn:hover{
  background-color: cyan;
}
</style>
