import Vue from 'vue'
import VueRouter from 'vue-router'
// import {auth} from '@/firebase/firebaseInit.js';
import login from '../views/Login.vue'
import Home from '../views/Home.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter, VueMeta)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: login,
  },
  {
    path: "/passwordReset",
    name: "passwordReset",
    component: () =>  import ('@/components/PasswordReset.vue'),
    meta: {
        requiresAuth : false    
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

//   if (requiresAuth && !auth.currentUser) {
//     next('/')
//   } else {
//     next()
//   }
// })

export default router
