<template>
  <nav :class="{ 'navbar--hidden': showNavbar }" class="navbar z-50 bg-white shadow w-full flex justify-between items-center mx-auto px-8 h-10 rounded-b-3xl">
    <!-- logo -->
    <div class="inline-flex">
        <a class="_o6689fn" href="/"
            ><div class="hidden md:block">
                <h1 class="text-gray-700 font-bold">OMBIKA</h1>
            </div>
            <div class="block md:hidden">
                <h1 class="text-gray-700 font-bold">OMBIKA</h1>
            </div>
        </a>
    </div>

    <!-- end logo -->

    <!-- login -->
    <div class="flex-initial">
      <div class="flex justify-end items-center relative">
      
        <div class="flex mr-4 items-center">
          <a class="inline-block py-2 px-3 hover:bg-gray-200 rounded-full" href="#">
            <div v-if="user_loggedin" @click="logout()" class="flex items-center relative cursor-pointer whitespace-nowrap">Logout</div>
            <!-- <div v-else class="flex items-center relative cursor-pointer whitespace-nowrap">Login</div> -->
          </a>
        </div>
      </div>
    </div>
    <!-- end login -->
  </nav>
</template>
<script>
import {auth} from '@/firebase/firebaseInit.js';
export default {
  data () {
    return {
      user_loggedin:false,
      showNavbar: true,

      metaInfo() {
        return { 
          title: "Ombika - Selling Fee Calculator",
          meta: [
            { name: 'description', content:  'Calculate the selling fee and seller profit on the product'},
            { property: 'og:title', content: "Ombika - Selling Fee Calculator"},
          ]
        }
      }
      // lastScrollPosition: 0
    }
  },
  mounted () {
  const user = auth.currentUser;
  if (user) {
    this.user_loggedin = true
  } 
  else {
    this.user_loggedin = false
  } 
  window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      this.showNavbar = currentScrollPosition < 45
    },

    logout(){
      this.$store.dispatch('logout')
    }
  }

}
</script>

<style>
.navbar {
  position: fixed;
  transform: translate3d(0, 0, 0);
  transition: 0.1s all ease-out;
}
.navbar.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
@media screen and (max-width: 768px) {
  .navbar.navbar--hidden {
    box-shadow: none;
    transform: translate3d(0, 0, 0);
  }
}
</style>