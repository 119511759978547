
 var firebaseConfig = {
  apiKey: "AIzaSyCNHC2x-pZGPipoS8RMUM0d8FC_MKBlf64",
  authDomain: "ombika-83e4b.firebaseapp.com",
  projectId: "ombika-83e4b",
  storageBucket: "ombika-83e4b.appspot.com",
  messagingSenderId: "98866711439",
  appId: "1:98866711439:web:a0d3126fc4e670be8799fa"
};
  // Initialize Firebase
  // firebase.initializeApp(firebaseConfig);
export default firebaseConfig    