<template>
  <div class="home">
    <Header />
    <Body />
    <HelloWorld />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Body from '@/components/body.vue'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Body,
    HelloWorld
  }
}
</script>
